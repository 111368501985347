/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import clsx from 'clsx'
import Fuse from 'fuse.js'
import { Drawer, Grid2, Box, useTheme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'

import AnomalyDrawer from '../elements/AnomalyDrawer.js'
import AnomalyReportsButton from '../elements/AnomalyReportsButton.js'
import AsseTracAppBar from '../elements/AsseTracAppBar.js'
import AsseTracMap from '../elements/AsseTracMap.js'
import InactivateProjectDialog from '../dialogs/InactivateProjectDialog.js'
import InventoryPanel from '../elements/InventoryPanel.js'
import LoadingOverlay from '../elements/LoadingOverlay.js'
import MapOverlays from '../elements/MapOverlays.js'
import MovesTable from '../elements/MovesTable.js'
import ProjectToolbar from '../elements/ProjectToolbar.js'
import UploadOverlayDialog from '../dialogs/UploadOverlayDialog.js'
import UpdateProjectDialog from '../dialogs/UpdateProjectDialog.js'
import AssetToolTip from '../elements/AssetTooltip.js'

import { useStateContext } from '../../store/stateContext.js'
import { useCustomerActions } from '../../store/Customer/CustomerActions.js'
import { useMapActions } from '../../store/Map/MapActions.js'
import { useProjectActions } from '../../store/Project/ProjectActions.js'
import { useAssetsActions } from '../../store/Assets/AssetsActions.js'
import { SET_ICONLAYERS, SET_PATHLAYERS } from '../../store/Map/MapReducer.js'

import { filterBy, isAssetConnected, safeGet, formatDateTime } from '../../util.js'

const ASSET_SEARCH_OPTS = {
  keys: [
    {
      name: 'uuid',
      weight: 0.5,
    },
    {
      name: 'name',
      weight: 0.2,
    },
    {
      name: 'type.name',
      weight: 0.2,
    },
    {
      name: 'description',
      weight: 0.1,
    },
  ],
}

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    height: '100vh',
    // overflow: 'auto',
    // flexWrap: 'nowrap',
  },
  mapWrap: {
    // flexGrow: 99999999999,
    flexGrow: 1,
    // height: '100hv',
  },
  toolbarWrap: {
    width: '100%',
  },
  drawerPersistent: {
    width: theme.spacing(42),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .MuiDrawer-paper': {
      backgroundColor: theme.colors.backgroundColor,
    },
  },
  drawerPersistentClosed: {
    width: 0,
  },
  drawerPaper: {
    /* eslint-disable no-dupe-keys */
    /* Firefox */
    height: ' -moz-calc(100% - 49px)',
    /* WebKit */
    height: '-webkit-calc(100% - 49px)',
    /* Opera */
    height: '-o-calc(100% - 49px)',
    /* Standard */
    height: 'calc(100% - 49px)',
    /* eslint-enable no-dupe-keys */
    zIndex: 1,
    width: theme.spacing(42),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    boxSizing: 'border-box',
    marginTop: 49,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      height: '100%',
    },


  },

  inventoryPanel: {
    position: 'absolute',
    left: '2rem',
    bottom: '4rem',
    zIndex: 999,
    
  },
}))

const ProjectPage = () => {
  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()
  const { id: projectId } = useParams()

  const { state, dispatch } = useStateContext()
  const { getAssets, getAssetTypes } = useAssetsActions()
  const { getCustomerAssets } = useCustomerActions()
  const {
    addGeoJsonLayer,
    clearLayers,
    removeGeoJsonLayer,
    visibleOnMap,
    zoomToCoordinates,
  } = useMapActions()
  const { getProject } = useProjectActions()

  const [assets, setAssets] = useState([])
  const [assetTypeList, setAssetTypeList] = useState([])
  const [filteredAssets, setFilteredAssets] = useState([])
  const [assetsFilter, setAssetsFilter] = useState([])
  const [assetTypes, setAssetTypes] = useState([])
  const [anomalyFilter, setAnomalyFilter] = useState(null)
  const [activeProject, setActiveProject] = useState({})
  const [customerId, setCustomerId] = useState('')

  const [curTab, setCurTab] = useState(0)
  const [curTabMap, setCurTabMap] = useState(true)
  const [curTabMoves, setCurTabMoves] = useState(false)

  const handleTabChange = (event, newValue) => {
    setCurTab(newValue)
  }

  useEffect(() => {
    let isSubscribed = true
    if (state.customers.activeCustomer && state.customers.activeCustomer.id) {
      setCustomerId(state.customers.activeCustomer.id)
      if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
        // This delay prevents a race condition with the map causing asset nodes to sometimes not appear in Safari.
        // Haven't ever seen the issue with this set to 1000ms, but did see it once when set to 500ms.
        window.setTimeout(
          () =>
            isSubscribed
              ? getCustomerAssets(state.customers.activeCustomer.id, projectId)
              : null,
          1000,
        )
      } else {
        getCustomerAssets(state.customers.activeCustomer.id, projectId)
      }
    }
    return () => (isSubscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.customers.activeCustomer])

  useEffect(() => {
    const boundaries = safeGet('boundaries', activeProject)
    if (boundaries instanceof Array && boundaries.length > 0) {
      zoomToCoordinates(boundaries)

      dispatch({
        type: SET_PATHLAYERS,
        payload: {
          pathLayers: {
            ...state.map.pathLayers,
            [projectId]: {
              id: projectId,
              boundaries: boundaries,
            },
          },
          suppressIcon: true,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProject])

  useEffect(() => {
    //setAssetHistoryList(getAssetsHistory())
    setAssets(getAssets())
    setAssetTypes(getAssetTypes())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.assets.assetList,
    state.assets.assetTypes,
    state.assets.projectAssetList,
  ])

  useEffect(() => {
    setAssets([])
    setAssetTypes([])
    setActiveProject(getProject(projectId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.projects.projectList])

  useEffect(() => {
    // on mount map reset
    clearLayers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const tempAssetTypes = getAssetTypes()

    const types = tempAssetTypes.reduce(
      (acc, type) => {
        acc.push({
          id: type.name.toLowerCase(),
          name: type.name,
          children: safeGet('subType.items', type),
        })

        // add sub types to list
        if (type.subType && type.subType instanceof Array) {
          for (let subType of type.subType) {
            acc.push({
              id: subType.name.toLowerCase(),
              name: subType.name,
            })
          }
        }

        return acc
      },
      [
        {
          id: '',
          name: 'Unknown',
        },
      ],
    )

    setAssetTypeList(types)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.assets.assetTypes])

  useEffect(() => {
    let results = assets
    if (!results || results.length === 0) {
      return
    }

    // filter by checkboxes
    results = filterBy({
      list: results,
      filters: assetsFilter.checkFilter,
      props: ['type.name', 'subType.name'],
      override: (asset) =>
        !asset.type && assetsFilter.checkFilter.indexOf('') > -1,
    })

    // filter by text field
    if (assetsFilter.textFilter) {
      const fuse = new Fuse(results, ASSET_SEARCH_OPTS)

      results = fuse.search(assetsFilter.textFilter)
    }

    // filter by anomaly
    if (anomalyFilter) {
      if (anomalyFilter === 'Low Battery') {
        //TODO: check because asset doesn't have assetHistory prop
        // results = filterBy({
        //   list: results,
        //   filters: [0],
        //   props: ['assetHistory.items.0.battery'],
        // })
        results = results.filter((asset) => asset.battery < 2)
      }
      if (anomalyFilter === 'Lost Connection') {
        results = results.filter((asset) => !isAssetConnected(asset))
      }
    }

    // filter by viewport
    results = results.filter((asset) => visibleOnMap(asset.coordinate))

    setFilteredAssets(results)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    anomalyFilter,
    assets,
    assetsFilter,
    assetTypes,
    state.map.viewport,
    state.map.currentMapId,
  ])

  useEffect(() => {
    const iconLayers = {}

    for (let asset of filteredAssets) {
      const coordinates = asset.coordinate

      if (!coordinates) {
        continue
      }

      iconLayers[asset.id] = {
        id: asset.id,
        icon: asset.subType.iconName,
        unitNumber: safeGet('unitNumber', asset),
        typeName: safeGet('type.name', asset),
        subTypeName: safeGet('subType.name', asset),
        lastReceived: formatDateTime(
          safeGet('receivedTs', asset),
          'M/D/YY h:mmA',
        ),
        coordinates,
        onClick(info) {
          const id = safeGet('object.properties.id', info)
          navigate(`/Asset/${id}`)
        },
      }
    }

    dispatch({
      type: SET_ICONLAYERS,
      payload: {
        iconLayers,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAssets])

  useEffect(() => {
    setCurTabMap(curTab === 0)
    setCurTabMoves(curTab === 1)
  }, [curTab])


  /**
   * When map is ready, zoom to project boundaries
   */
  const onMapInitialize = () => {
    if (!curTabMap) return
    setAssets([])
    setAssetTypes([])
    setActiveProject(getProject(projectId))
  }

  const onOverlaySelect = (id, geoJson, isChecked) => {
    //geoJson.features = geoJson.features.slice(-1)
    if (isChecked) {
      addGeoJsonLayer(id, geoJson)
    } else {
      removeGeoJsonLayer(id, geoJson)
    }
  }

  return (
    <>
      <Grid2 container direction='column' className={classes.pageWrap}>
        <AsseTracAppBar title='ProjectInfo' project={activeProject} />
        <Grid2
          container
          direction={curTabMap ? 'row' : 'column'}
          sx={{ flexGrow: 1 }}>
          <Grid2 container
            // xs={curTabMap}
            sx={{ width: '100%', flexGrow: 1, height: '100%' }}
            direction='column'>
            <ProjectToolbar
              assetsFilter={assetsFilter}
              setAssetsFilter={setAssetsFilter}
              assetTypeList={assetTypeList}
              setAnomalyFilter={setAnomalyFilter}
              anomalyFilter={anomalyFilter}
              handleTabChange={handleTabChange}
              setAssetTypeList={setAssetTypeList}
              curTab={curTab}
            />
            <Grid2 className={classes.mapWrap} hidden={!curTabMap}>
              <AsseTracMap onInitialize={onMapInitialize} />
              <AssetToolTip tooltip={state.map.tooltip} />
              {anomalyFilter && <AnomalyReportsButton />}
              <InventoryPanel
                className={classes.inventoryPanel}
                inventory={filteredAssets.filter(
                  (asset) => asset.projectId !== 'none',
                )}
              // Use below instead if checking for visibility on the map and including loose assets in inventory
              // inventory={filteredAssets}
              />
            </Grid2>
            <div hidden={!curTabMoves} style={{ flexGrow: 1 }}>
              <MovesTable customerId={customerId} projectId={projectId} />
            </div>
            <LoadingOverlay loading={state.assets.loading} />
          </Grid2>
          {curTabMap && (
            <>
              {smDown && (
                <Grid2 >
                  <Drawer
                    variant='temporary'
                    anchor={'right'}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                    open={state.ui.tempDrawerMapOverlaysOpen}
                  >
                    <MapOverlays
                      project={activeProject}
                      onChange={onOverlaySelect}
                    />
                  </Drawer>
                </Grid2>
              )}
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Grid2

                  className={clsx(classes.drawerPersistent, {
                    [classes.drawerPersistentClosed]:
                      state.ui.projectMapFullScreen,
                  })}
                >
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant='persistent'
                    anchor={'right'}
                    open={!state.ui.projectMapFullScreen}

                  >
                    <MapOverlays
                      project={activeProject}
                      onChange={onOverlaySelect}
                    />
                  </Drawer>
                </Grid2>
              </Box>
            </>
          )}

        </Grid2>
      </Grid2>
      <AnomalyDrawer anomaly={anomalyFilter} data={filteredAssets} />
      <UpdateProjectDialog
        onSubmit={() => {
          onMapInitialize()
          enqueueSnackbar('Successfully updated project.', {
            variant: 'success',
          })
        }}
        project={activeProject}
      />
      <InactivateProjectDialog
        onSubmit={() => {
          onMapInitialize()
          if (activeProject.status === 'active') {
            enqueueSnackbar('Started inactivating project.', {
              variant: 'success',
            })
          }
          if (activeProject.status === 'inactive') {
            enqueueSnackbar('Started re-activating project.', {
              variant: 'success',
            })
          }
        }}
        project={activeProject}
      />
      <UploadOverlayDialog
        onSubmit={(updatedProject) => setActiveProject(updatedProject)}
        project={activeProject}
      />
    </>
  )
}

export default ProjectPage
