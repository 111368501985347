import React, { useEffect } from 'react'
import { useUIActions } from '../../store/UI/UIActions.js'
import { useProjectActions } from '../../store/Project/ProjectActions.js'
import { useAuthActions } from '../../store/Auth/AuthActions.js'
import { useStateContext } from '../../store/stateContext.js'

import AsseTracAppBar from '../elements/AsseTracAppBar.js'
import ProjectList from '../elements/ProjectList.js'

import { Container, Grid2, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SystemIcon from '../elements/SystemIcon.js'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  addProjBtn: {
    marginLeft: 'auto',
  },
  Typography: {
    color: theme.colors.portlandCement,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  projectListContainer: {
    marginTop: theme.spacing(2),
  },
  inactiveContainer: {
    marginTop: theme.spacing(14),
  },
}))

export default () => {
  const classes = useStyles()
  const { memberOf } = useAuthActions()
  const { openNewProjectDialog } = useUIActions()
  const { getProjectList } = useProjectActions()
  const { state } = useStateContext()
  const projectsState = state.projects

  useEffect(() => {
    getProjectList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsState.projectList])

  return (
    <Grid2 container direction='column'>
      <AsseTracAppBar title='PROJECTS' />
      <Container className={classes.container} >
        {projectsState.projectList.length === 0 &&
          projectsState.inactiveProjectList.length === 0 && (
            <Grid2 container >
              {memberOf(/admins|customerAdmin[\S]+/) && (
                <>
                  <Grid2 xs={12}>
                    <Typography
                      variant='h4'
                      align='center'
                      data-test='initialProjectBanner'
                      className={classes.Typography}
                    >
                      Looks like we're going to have to start fresh
                    </Typography>
                  </Grid2>
                  <Grid2 >
                    <Button
                      color='primary'
                      variant='contained'
                      data-test='initialProjectCreateButton'
                      onClick={openNewProjectDialog}
                    >
                      Get to Work!
                    </Button>
                  </Grid2>
                </>
              )}
            </Grid2>
          )}
        {(projectsState.projectList.length > 0 ||
          projectsState.inactiveProjectList.length > 0) && (
            <Grid2 container direction='column'>
              <Grid2
                container
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  marginTop: 2
                }}>
                <Typography variant='h6' color='secondary' >
                  ACTIVE PROJECTS
                </Typography>
                {memberOf(/admins|customerAdmin[\S]+/) && (
                  <Button
                    color='primary'
                    className={classes.addProjBtn}
                    variant='contained'
                    data-test='projectCreateButton'
                    onClick={openNewProjectDialog}
                    sx={{ borderRadius: 5 }}
                  >
                    <SystemIcon name='icn_add' color='white' />
                    ADD PROJECT
                  </Button>
                )}
              </Grid2>
              <Grid2 xs={12} className={classes.projectListContainer}>
                <ProjectList projects={projectsState.projectList} />
              </Grid2>
              <Grid2
                container
                className={classes.inactiveContainer}
                alignItems='center'
                justify='space-between'
              >
                <Typography variant='h6' color='secondary'>
                  INACTIVE PROJECTS
                </Typography>
              </Grid2>
              <Grid2 xs={12} className={classes.projectListContainer}>
                <ProjectList projects={projectsState.inactiveProjectList} />
              </Grid2>
            </Grid2>
          )}
      </Container>
    </Grid2>
  )
}
