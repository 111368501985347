import React from 'react'

import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'center',
    paddingLeft: theme.spacing(1),
    flexGrow: 1,
    // backgroundColor: 'red'
  },
}))

export default (props) => {
  const classes = useStyles()

  return (
    <Typography className={classes.root} variant='h6'>
      {props.children}
    </Typography>
  )
}
