/* eslint-disable no-unused-vars */

import { IconLayer, TextLayer } from '@deck.gl/layers'
import { CompositeLayer } from '@deck.gl/core'

import ClusterBubble from '../assets/img/svg/cluster_bubble.svg'

import { safeGet } from '../util.js'

export default class AssetLayer extends CompositeLayer {
  renderLayers() {
    return [
      new IconLayer({
        data: this.props.data,
        getIcon: () => {
          return {
            url: ClusterBubble,
            width: 40,
            height: 49,
            anchorY: 49,
          }
        },
        getPosition: (d) => d.geometry.coordinates,
        getSize: 60,
        id: `icon-layer-${this.id}`,
        opacity: 1,
        pickable: true,
        widthMinPixels: 2,
        widthScale: 20,
      }),
      new IconLayer({
        data: this.props.data,
        getIcon: (d) => {
          var height = 40
          var width = height
          if (d.properties.point_count) {
            height = 0
            width = 0
          }

          return {
            url: d.properties.icon,
            width: height,
            height: width,
            anchorY: 44,
          }
        },
        getPosition: (d) => d.geometry.coordinates,
        getSize: 40,
        id: `icon-layer-${this.id}`,
        opacity: 1,
        pickable: true,
        widthMinPixels: 2,
        widthScale: 20,
      }),
      new TextLayer({
        data: this.props.data,
        fontWeight: 700,
        getAlignmentBaseline: 'center',
        getColor: () => [255, 255, 255, 255],
        getPixelOffset: [0, -24],
        getPosition: (d) => d.geometry.coordinates,
        getSize: 14,
        getText: (d) =>
          String(safeGet('properties.point_count_abbreviated', d) || ''),
        getTextAnchor: 'middle',
        id: `text-layer-${this.id}`,
        pickable: true,
      }),
    ]
  }
}

AssetLayer.layerName = 'AssetLayer'
