import React from 'react'
import { Link } from 'react-router-dom'

import { Grid2, Card, CardContent, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  note: {
    textAlign: 'center',
    color: theme.colors.portlandCement,
  },
  card: {
    minWidth: 264,
    cursor: 'pointer',
    border: '1px solid',
    borderColor: theme.colors.lightSteel
  },
  content: {
    backgroundColor: theme.colors.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 50,
  },
  projectName: {
    color: theme.colors.quillFeather,
    fontWeight: 'bold'
  },
  projectClient: {
    color: theme.colors.portlandCement,
  },
}))

export default ({ projects }) => {
  const classes = useStyles()

  return (
    <Grid2 container spacing={2} justifyContent='flex-start'>
      {projects.length === 0 && (
        <Grid2 container justify='space-around'>
          <Typography className={classes.note}>
            There are no projects to display
          </Typography>
        </Grid2>
      )}
      {projects.map((p) => {
        return (
          <Grid2 key={p.name} xs={12} sm={6} md={4} lg={3}>
            <Link to={'/Projects/' + p.id} >
              <Card className={classes.card} data-test={'project' + p.name} elevation={0}>
                <CardContent className={classes.content} >
                  <Typography className={classes.projectName} variant='h6'>
                    {p.name}
                  </Typography>
                  <Typography
                    className={classes.projectClient}
                    variant='subtitle1'
                  >
                    {p.client}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid2>
        )
      })}
    </Grid2>
  )
}
