import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { debounce } from '../../util.js'

import {
  AppBar,
  Toolbar,
  Grid2,
  IconButton,
  SvgIcon,
  TextField,
  Divider,
  Box,
  useMediaQuery,
  Button,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import PageTitle from './PageTitle.js'
import AssetIcon from './AssetIcon.js'
import ProjectInfo from './ProjectInfo.js'
import SearchDrawer from './SearchDrawer.js'

import Brand from '../../assets/brand/Matrac.svg'
import Icons from '../../assets/icons/symbol-defs.svg'
import { useUIActions } from '../../store/UI/UIActions.js'
import { useSearchActions } from '../../store/Search/SearchActions.js'
import { useStateContext } from '../../store/stateContext.js'
import {
  SET_QUICK_SEARCH_RESULTS,
  SET_SEARCH_CRITERIA,
} from '../../store/Search/SearchReducer.js'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: '1px solid',
    borderBottomColor: theme.colors.lightSteel,
  },
  toolbarContents: {
    height: 55,
    // width: '100%',
    justifyContent: 'space-between',
    // flexWrap: 'nowrap',
    backgroundColor: theme.colors.backgroundColor,
    // backgroundColor: 'green'
  },
  hamburgerWrap: {
    // zIndex: 1,
    // marginRight: theme.spacing(-2),
    height: '100%',
  },
  menuButton: {
    // marginRight: 2
  },
  assetIconWrap: {
    flexGrow: 1,
    paddingLeft: 2,
    alignItems: 'center',
  },
  brand: {
    alignItems: 'center',
    height: '100%',
    // zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 3,
      left: '40%',
    },
    // [theme.breakpoints.down('sm')]: {
    //   position: 'static',
    //   paddingTop: 6,
    //   marginRight: 'auto',
    // },
  },
  brandLockup: {
    // maxHeight: 30,
    maxWidth: 200,
    // alignSelf: 'center',
  },
  brandMark: {
    // maxHeight: 30,
    // maxWidth: 32,
    // marginRight: theme.spacing(2),
  },
  searchContainer: {
    // marginRight: theme.spacing(5),
    // height: '100%',
    // // backgroundColor: 'red',
    // width: theme.spacing(38),
    // alignContent: 'center',

    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    // '&:hover': {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    // [theme.breakpoints.up('xs')]: {
    //   marginLeft: 'auto',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: theme.spacing(30),
    // },
    // [theme.breakpoints.down('xs')]: {
    //   display: 'flex',
    //   justifyContent: 'flex-end',
    //   width: 'unset',
    //   flexGrow: 1,
    // },
  },
  searchField: {
    // color: 'white',
    width: '100%',
    '& .MuiInputBase-input': {
      color: 'white',
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)})`,
      // transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '35ch',
        // '&:focus': {
        //   width: '20ch',
        // },
      },
    },
    // border: '1px solid',
    // borderColor: theme.colors.lightSteel
    // width: theme.spacing(38),
    // // height:40,
    // // marginTop: theme.spacing(0.5),
    // zIndex: 1210,
    // [theme.breakpoints.down('sm')]: {
    //   width: theme.spacing(30),
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: '100%',
    // },
    // '& input': {
    //   background: theme.colors.backgroundColor,
    //   borderRadius: 4,
    // },
  },
  closeSearchButton: {
    zIndex: 1201,
  },
  divider: {
    zIndex: 20000,
  },
}))

const AsseTracAppBar = ({ title = '', project = {}, icon = '' }) => {
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()

  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const {
    dispatch,
    state: { ui: uiState },
  } = useStateContext()
  const {
    openDrawerNav,
    openDrawerSearch,
    closeDrawerSearch,
    showSearchBar,
    hideSearchBar,
  } = useUIActions()
  const { getSearchResults } = useSearchActions()

  const handleSearchChange = (e) => {
    const value = e.target.value
    if (value === '') {
      closeDrawerSearch()
    } else {
      openDrawerSearch()
      dispatch({
        type: SET_QUICK_SEARCH_RESULTS,
        payload: {},
      })
      dispatch({
        type: SET_SEARCH_CRITERIA,
        payload: value,
      })
      debounce(
        'searchCriteria',
        () => {
          getSearchResults(value)
        },
        300,
      )
    }
  }

  const handleSearchFocus = (e) => {
    const value = e.target.value
    if (value !== '' && value !== null) {
      openDrawerSearch()
    }
  }

  return (
    <Box>
      <AppBar
        position={mdUp ? 'fixed' : 'static'}
        elevation={0}
        data-test='appBar'
        className={classes.toolbar}
      >
        <Toolbar variant='dense' className={classes.toolbarContents}>
          {/* Menu Icon Button */}
          {/* <Grid2
            className={classes.hamburgerWrap}
            container
          > */}
          <IconButton
            data-test='openDrawerButton'
            onClick={openDrawerNav}
            edge='start'
            className={classes.menuButton}
            aria-label='menu'
            size='large'
            sx={{
              mr: 1,
            }}
          >
            <SvgIcon sx={{ fill: 'white' }}>
              <svg>
                <use xlinkHref={Icons + '#icn_hamburger'} />
              </svg>
            </SvgIcon>
          </IconButton>

          <Divider
            className={classes.divider}
            orientation='vertical'
            flexItem
            variant='fullWidth'
            sx={{ backgroundColor: theme.colors.lightSteel }}
          />

          {/* Title */}
          {((!uiState.searchBarVisible && smDown) || smUp) && title && (
            <>
              {(() => {
                if (title === 'ProjectInfo') {
                  return <ProjectInfo project={project} />
                } else if (title === 'asset') {
                  return (
                    <Grid2 container className={classes.assetIconWrap}>
                      <Button
                        variant='text'
                        startIcon={<ChevronLeftIcon />}
                        onClick={() => navigate(-1)}
                        color='secondary'
                      >
                        Back to project
                      </Button>
                    </Grid2>
                  )
                } else if (title && icon) {
                  return (
                    <Grid2 container className={classes.assetIconWrap}>
                      <AssetIcon name={icon} />
                      <PageTitle>{title}</PageTitle>
                    </Grid2>
                  )
                } else if (title) {
                  return <PageTitle>{title}</PageTitle>
                }
              })()}
            </>
          )}
          {/* </Grid2> */}

          {/* Assetrac Logo */}
          {mdUp && (
            <Grid2 container className={classes.brand}>
              <Grid2>
                <Link to='/'>
                  <svg className={classes.brandLockup}  height={20} viewBox='0 0 195 20'>
                    <use xlinkHref={Brand + '#brand-icon'} />
                  </svg>
                </Link>
              </Grid2>
            </Grid2>
          )}

          {/* Search Component */}
          <Grid2 className={classes.searchContainer}>
            {xsDown && !uiState.searchBarVisible && (
              <>
                <Divider className={classes.divider} orientation='vertical' />
                <IconButton edge='end' onClick={showSearchBar}>
                  <SvgIcon>
                    <svg className={classes.brandMark}>
                      <use xlinkHref={Icons + '#icn_search'} />
                    </svg>
                  </SvgIcon>
                </IconButton>
              </>
            )}

            {(smUp || uiState.searchBarVisible) && (
              <TextField
                onFocus={handleSearchFocus}
                className={classes.searchField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme.colors.lightSteel,
                    },
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: theme.colors.lightSteel,
                    fontWeight: 'bold',
                  },
                }}
                fullWidth
                variant='outlined'
                placeholder='SEARCH ALL'
                onChange={handleSearchChange}
              />
            )}

            {xsDown && uiState.searchBarVisible && (
              <Box>
                <IconButton
                  className={classes.closeSearchButton}
                  edge='end'
                  onClick={() => {
                    hideSearchBar()
                    closeDrawerSearch()
                  }}
                >
                  <SvgIcon>
                    <svg className={classes.brandMark}>
                      <use xlinkHref={Icons + '#icn_x'} />
                    </svg>
                  </SvgIcon>
                </IconButton>
              </Box>
            )}
            <SearchDrawer />
          </Grid2>
        </Toolbar>
      </AppBar>
      {mdUp && <Toolbar variant='dense' />}
    </Box>
  )
}

export default AsseTracAppBar
